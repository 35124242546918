@font-face {
  font-family: 'ALOEVERADISPLAY';
  src: url('./Assets/Fonts/ALOEVERADISPLAY-REGULAR.TTF') format('truetype');
}

html{
  font-family: 'poppins';
  /* width: 1366px;
  min-height: 728px;
  max-height: 766px; */
  overflow-y: auto;
  /* border-bottom: 1px solid black; */
  margin: auto;
}

/* .common-border-gradient{
  border-image: linear-gradient(to bottom, #CC97FB, #FFFFFF) 1;
  border-radius: 2em !important;
} */

.transition-md{
  transition: all 1s ease-in-out;
}

.transition-sm{
  transition: all 0.5s ease-in-out;
}

.fastranking-total-score, .sparta-total-score, .vee-total-score {
  transform: translateX(110%);
  display: inline-block; 
  animation: fastranking_total_score 10s ease-in-out infinite;
  animation-delay: 4s; /* Starts immediately */
}

.sparta-total-score {
  animation-delay: 14s; /* Delays for sparta by 13s */
}

.vee-total-score {
  animation-delay: 24s; /* Delays for vee by 26s */
}

@keyframes fastranking_total_score {
  0% {
    transform: translateX(110%); /* Off-screen */
  }
  20% {
    transform: translateX(0%); /* In place (between 2s and 8s) */
  }
  80% {
    transform: translateX(0%); /* Stay in place until 8s */
  }
  100% {
    transform: translateX(110%); /* Off-screen again */
  }
}


